import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/Features";
//import BlogRoll from "../components/BlogRoll";

export const IndexPageTemplate = ({
  image,
  title,
  news,
  subheading,
  what,
  intro,
  locale
}) => (
    <div>
      <div className="hero">
        <div className="container">
          <h1>{title}</h1>
          <p>{subheading}</p>
          <Link to="/contact">{locale === "en" ? ("EXPLORE IT") : ("KEŞFET")}</Link>
          <img
            src={
              image.childImageSharp
                ? image.childImageSharp.fluid.src
                : image
            }
            alt={title}
            title={title}
          />
        </div>
      </div>
      <div className="what">
        <div className="container">
          <div className="section-title">{what.title}</div>
          <p></p>
          <div className="columns">
            <div className="w1">
              <div className="left">
                <h3>{what.h1}</h3>
              </div>
              <div className="right">
                <p>{what.t1}</p>
              </div>
            </div>
            <div className="w2">
              <h3>{what.h2}</h3>
              <p>{what.t2}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="features">
        <div className="container">
          <div className="section-title">{intro.heading}</div>
          <Features gridItems={intro.blurbs} />
        </div>
      </div>
    </div>
  );


IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  locale: PropTypes.string,
  news: PropTypes.object,
  subheading: PropTypes.string,
  what: PropTypes.object,
  intro: PropTypes.shape({
    blurbs: PropTypes.array
  })
};

const IndexPage = ({ pageContext: { locale }, ...props }) => {
  const { node: data } = props.data.homePageData.edges[0]
  const { edges: posts } = props.data.blogPosts
  return (
    <Layout locale={locale}>
      <IndexPageTemplate
        image={data.frontmatter.image}
        title={data.frontmatter.title}
        news={data.frontmatter.news}
        subheading={data.frontmatter.subheading}
        what={data.frontmatter.what}
        intro={data.frontmatter.intro}
        locale={data.frontmatter.locale}
      />
      <div className="our-news">
        <div className="container">
          <div className="section-title">{data.frontmatter.news.title}</div>
          <div className="columns is-multiline">
            {posts.slice(0, 2).map(({ node: post }) => {
              return (<div className="is-parent column" key={post.id}>
                <article>
                  <div>
                    <Link className="title" to={post.fields.slug} title={post.frontmatter.title}>
                      {post.frontmatter.title}
                    </Link>
                    <span className="date">{post.frontmatter.date}</span>
                  </div>
                  <p>{post.excerpt}</p>
                  <Link className="button" to={post.fields.slug} title={post.frontmatter.title}>
                    {locale === "en" ? ("Keep Reading →") : ("Okumaya devam et →")}
                  </Link>
                </article>
              </div>);
            })}
          </div>
        </div>
      </div>

    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  }),
  posts: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  })
};

export default IndexPage;

export const pageQuery = graphql`
query IndexPageTemplate($locale: String) {
  homePageData: allMarkdownRemark(
    filter: {frontmatter: { templateKey: {eq: "index-page"}, locale: { eq: $locale }}}
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          templateKey
          locale
          title
          what {
            title
            h1
            t1
            h2
            t2
          }
          news {
            title
            description
          }
          image {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          subheading
          description
          intro {
            blurbs {
              image {
                childImageSharp {
                  fluid(maxWidth: 160, quality: 64) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              header
              text
            }
            heading
            description
          }
         }
        }
      }
    }

  blogPosts: allMarkdownRemark(
      filter: {frontmatter: { pageKey: {eq: "page_blogpost"}, locale: { eq: $locale }}}
    ) {
      edges {
        node {
          fields {
            slug
          }
          excerpt(pruneLength: 200)
          id
          frontmatter {
            title
            pageKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`
